var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"main_page"},[_c('app-navbar'),_c('div',{staticClass:"page"},[_c('loading',{attrs:{"active":_vm.isPageLoading,"is-full-page":""},on:{"update:active":function($event){_vm.isPageLoading=$event}}}),(!_vm.isPageLoading)?_c('div',[_c('div',{staticClass:"page-title"},[_c('h1',[_vm._v("Owners")])]),_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"searchKeyword"}},[_vm._v("Search by Keyword")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchCriteria.keyword),expression:"searchCriteria.keyword"}],staticClass:"form-control",attrs:{"type":"text","id":"searchKeyword","name":"searchKeyword","autocomplete":"off"},domProps:{"value":(_vm.searchCriteria.keyword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.searchCriteria, "keyword", $event.target.value)}}})]),_c('div',{staticClass:"col-sm-2"},[(_vm.$permissions().hasEditOwnerPermission)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.newOwner}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" Add New ")]):_vm._e()])]),_c('data-table',{attrs:{"data":_vm.searchResults.results,"columns":_vm.columns,"total":_vm.searchResults.total,"query":_vm.searchCriteria,"isLoading":_vm.isDataLoading,"column-filter":false},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}},{key:"address",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getAddressDisplay(row.address) || '---'))]}},{key:"phone",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.phone || '---'))]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [(_vm.$permissions().hasEditOwnerPermission)?_c('a',{staticClass:"d-flex justify-content-between align-items-center flex-column",attrs:{"href":"#","role":"button","title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editOwner(row.id)}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/document.svg"),"alt":""}}),_c('span',{staticClass:"small-grey"},[_vm._v("Edit")])]):_vm._e()]}},{key:"delete",fn:function(ref){
var row = ref.row;
return [(_vm.$permissions().hasDeleteOwnerPermission)?_c('a',{staticClass:"d-flex justify-content-between align-items-center flex-column",attrs:{"href":"#","role":"button","title":"Delete"},on:{"click":function($event){$event.preventDefault();return _vm.deleteOwner(row.id)}}},[_c('img',{staticClass:"mb-1",attrs:{"src":require("@/assets/red_x.svg"),"alt":""}}),_c('span',{staticClass:"small-grey"},[_vm._v("Delete")])]):_vm._e()]}},{key:"loading",fn:function(){return [_c('loading',{attrs:{"active":_vm.isDataLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isDataLoading=$event}}})]},proxy:true},{key:"no-records",fn:function(){return [_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("No records found.")])]},proxy:true}],null,false,46247337)})],1)]):_vm._e()],1)],1),_c('b-modal',{attrs:{"id":"bv-modal-owner","size":"lg","hide-header":"","hide-footer":"","no-close-on-backdrop":true}},[_c('owner',{on:{"save-action":_vm.onOwnerSave}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
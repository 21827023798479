const defaultOptions = {
  noCloseButton: true,
  autoHideDelay: 3000,
  toaster: "b-toaster-top-right",
  isStatus: true
};

const success = function (context, message, overrideOptions) {
  let options = { variant: 'success', ...defaultOptions, ...overrideOptions };
  context.$root.$bvToast.toast(message, options);
}

const danger = function (context, message, overrideOptions) {
  let options = { variant: 'danger', ...defaultOptions, ...overrideOptions };
  context.$root.$bvToast.toast(message, options);
}

export default { success, danger }

<template>
  <fragment>
    <div class="row align-items-center btn-toolbar mt-3" role="toolbar">
      <div class="col-lg-auto d-flex justify-content-center justify-content-lg-start">
        <div class="btn-group mb-0 d-print-none" role="group">
          <slot name="header-custom-btns"></slot>
        </div>
      </div>

      <div class="col py-3 text-center">{{ localDatasetTotal }} record(s) found</div>

      <div class="col-lg-auto d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-lg-end d-print-none">
        <div class="btn-group mr-2 mb-0 p-1" role="group">
          <button
            type="button"
            class="btn btn-link"
            @click="turnPage(-1)"
            :disabled="isFirstPage"
            aria-label="Previous page"
          >
            <i class="fa fa-angle-left fa-lg"></i>
          </button>

          <span class="btn">Page {{ currentPage }}</span>

          <button
            type="button"
            class="btn btn-link"
            @click="turnPage(1)"
            :disabled="isLastPage"
            aria-label="Next page"
          >
            <i class="fa fa-angle-right fa-lg"></i>
          </button>
        </div>

        <div class="mr-2 p-1">
          <label class="sr-only" for="PageSize">Results per page</label>
          <select
            id="PageSize"
            name="PageSize"
            class="form-control border-primary"
            v-model="query.pageSize"
            @change="onPageSizeChange()"
          >
            <option v-for="size in pageSizeOptions" :key="size" :value="size">{{ size }} / Page</option>
          </select>
        </div>

        <div class="dropdown p-1" v-if="columnFilter">
          <button
            class="btn btn-outline-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-label="Edit columns"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-columns"></i>
            <span class="caret"></span>
          </button>

          <div class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">Toggle columns</h6>
            <div class="dropdown-item" v-for="column in selectableColumns" :key="column.field">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="'-col-' + column.field"
                  :name="'-col-' + column.field"
                  :checked="column.isVisible"
                  @change="handleColumnSelection(column, $event.target.checked)"
                />
                <label class="form-check-label" :for="'-col-' + column.field">{{ column.display }}</label>
              </div>
            </div>

            <div class="dropdown-item">
              <button type="button" class="btn btn-primary btn-block" @click="applyColumnVisibility">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search results (hidden on print) -->
    <div class="table-responsive mt-3 d-print-none">
      <table :class="['table', { 'table-hover': hasData }]">
        <thead>
          <th v-if="selectable" class="check-all">
            <label class="control control-checkbox" for="checkall">
              <span class="sr-only">Select all rows</span>
              <input id="checkall" type="checkbox" name="checkall" :checked="isAllCurrentPageRecordsSelected" @click="checkAll" />
              <div class="control_indicator"></div>
            </label>
          </th>
          <th v-for="column in visibleColumns" :key="column.field">
            {{ column.display || '&nbsp;' }}
            <a
              v-if="column.sortable"
              href="#"
              role="button"
              aria-label="Sort"
              @click.prevent="handleSortColumn(column.field)"
            >
              <i :class="getSortDirection(column.field)"></i>
            </a>
            <!-- filter start -->
            <button
              v-if="isLocalData && column.filterable"
              :id="`${column.field}-filter`"
              class="btn btn-link dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-filter" aria-label="Filter"></i>
            </button>
            <div v-if="isLocalData && column.filterable" class="dropdown-menu" :aria-labelledby="`${column.field}-filter`">
              <!-- for each unique value in column -->
              <div v-for="item in column.filterOptions" :key="`filter-${column.field}-${item}`" class="dropdown-item">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" :id="`filter-${column.field}-${item}`" @change="handleFilterSelection(column.field, item, $event.target.checked)">
                  <label class="form-check-label" :for="`filter-${column.field}-${item}`">{{ item }}</label>
                </div>
              </div>
              <div v-if="!column.filterOptions.length" class="dropdown-item">
                No Filters
              </div>
              <!-- /for -->
              <div class="dropdown-item">
                <button class="btn btn-primary btn-block" type="button" @click="onFilter()">Apply</button>
              </div>
            </div>
            <!-- filter end -->
          </th>
        </thead>

        <tbody v-if="isLoading">
          <tr>
            <td :colspan="visibleColumns.length + 1">
              <slot name="loading">
                <div class="alert alert-warning" role="alert">Loading...</div>
              </slot>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="hasData">
          <template v-for="item in availableRecords">
            <tr :key="item.id" :class="onRowClass(item)" @click="if (onRowClick) onRowClick(item)" @dblclick="if (onRowDblClick) onRowDblClick(item)">
              <th scope="row" v-if="selectable">
                <label class="control control-checkbox" :for="`check-${item.id}`">
                  <span class="sr-only">Select row</span>
                  <input
                    :id="`check-${item.id}`"
                    type="checkbox"
                    name="category"
                    :value="item.id"
                    :checked="isSelected(item.id)"
                    @change="selectRecord($event.target.value)"
                  />
                  <div class="control_indicator"></div>
                </label>
              </th>
              <td v-for="column in contentColumns" :key="column.field">
                <slot :name="column.field" v-bind:row="item">
                  <!-- {{ getItemValue(item, column.field) }} -->
                </slot>
              </td>
              <slot v-bind:row="item"></slot>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="visibleColumns.length + 1">
              <slot name="no-records">
                <div class="alert alert-warning" role="alert">No records found.</div>
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Search results (print only) -->
    <div class="mt-3 d-none d-print-block" v-if="hasData">
      <template v-for="item in availableRecords">
        <dl :key="item.id" :class="onRowClass(item)">
          <fragment v-for="column in columns" :key="column.field">
            <dt>{{ column.display || '&nbsp;' }}</dt>
            <dd>
              <slot :name="column.field" v-bind:row="item"></slot>
            </dd>
          </fragment>
        </dl>
      </template>
    </div>
  </fragment>
</template>

<style scoped>
.check-all {
  vertical-align: baseline;
}
</style>

<script>
export default {
  name: "data-table",
  props: {
    columns: { type: Array, required: true },
    data: { type: Array, required: true },
    query: { type: Object, required: true },
    total: { type: Number, required: true },
    pageSizeOptions: { type: Array, default: () => [10, 20, 50, 100] },
    selectedRows: Array,
    selectable: Boolean,
    isLoading: { type: Boolean, default: () => false },
    columnFilter: { type: Boolean, default: true },
    onRowClick: { type: Function },
    onRowDblClick: { type: Function },
    rowClass: { type: [String, Function], default: '' },
    isLocalData: { type: Boolean, default: false }
  },
  data: function() {
    return {
      isOpen: false,
      columnChanges: [],
      filterChanges: [],
      localDataset: [],
      localDatasetTotal: 0,
    };
  },
  watch: {
    data: function(values) {
      this.localDataset = values;
      this.localDatasetTotal = this.total;

      if (this.isLocalData) {
        this.query.page = 1;
        this.localDatasetTotal = values.length;

        this.columns.forEach(column => {
          if (column.filterable) {
            this.$set(column, "filterOptions", this.generateColumnFilters(column));
          }
        });
      }
    },
  },
  computed: {
    isAllCurrentPageRecordsSelected() {
      return this.availableRecords.length && this.availableRecords.map(i => i.id).filter(i => !this.selectedRows.includes(i)).length === 0;
    },
    availableRecords () {
      if (this.isLocalData) {
        return [...this.localDataset].splice((+this.query.page - 1) * +this.query.pageSize, +this.query.pageSize);
      }
      return this.localDataset;
    },
    currentPage() {
      return this.query.page;
    },
    hasData () {
      return this.data.length > 0;
    },
    isFirstPage() {
      return +this.query.page === 1;
    },
    isLastPage() {
      return +this.query.page * +this.query.pageSize >= this.localDatasetTotal;
    },
    selectableColumns() {
      return this.columns.filter(column => !column.ignore);
    },
    visibleColumns() {
      return this.columns.filter(column => column.isVisible);
    },
    contentColumns() {
      return this.columns.filter(column => column.isVisible && !column.ignore);
    }
  },
  methods: {
    applyColumnVisibility() {
      this.columnChanges.forEach(({ column, isChecked }) => {
        this.$set(column, "isVisible", isChecked);
      });
      this.columnChanges = []; // don't forget to clear the stack
      this.$emit('selected-columns-changed', this.columns);
    },
    getItemValue(item, columnField) {
      let columnSplit = columnField.split(".");
      let parentColumn = columnSplit[0];

      if (columnSplit.length > 1) {
        columnSplit.shift();
        return this.getItemValue(item[parentColumn], columnSplit.join("."));
      }
      return item[parentColumn];
    },
    getSortDirection(fieldName) {
      let { sortOrder, sortDirection } = this.query;

      if (sortOrder === fieldName) {
        if (sortDirection === "asc") return "fa fa-sort-up";
        if (sortDirection === "desc") return "fa fa-sort-down";
      }
      return "fa fa-sort text-muted";
    },
    handleColumnSelection(column, isChecked) {
      this.columnChanges.push({ column, isChecked });
    },
    handleFilterSelection(field, value, isChecked) {
      this.filterChanges = [...this.filterChanges.filter(i => i.field !== field || i.value !== value), { field, value, isChecked }];
    },
    handleSortColumn(fieldName) {
      // reset the direction of new column is selected
      if (this.query.sortOrder !== fieldName) {
        this.query.sortDirection = null;
      }

      this.query.sortDirection = this.query.sortDirection === null ? "asc" : this.query.sortDirection === "asc" ? "desc" : null;
      this.query.sortOrder = this.query.sortDirection === null ? null : fieldName;
      this.$emit('column-sort-changed', { sortOrder: this.query.sortOrder, sortDirection: this.query.sortDirection });

      if (this.isLocalData) {
        this.localDataset.sort((cat1, cat2) => {
          let value1 = (cat1[this.query.sortOrder] || '').toLowerCase();
          let value2 = (cat2[this.query.sortOrder] || '').toLowerCase();

          if (value1 < value2) {
            return this.query.sortDirection === "asc" ? -1 : 1;
          }
          else if (value1 > value2) {
            return this.query.sortDirection === "asc" ? 1 : -1;
          }
          else {
            return 0;
          }
        });
      }
    },
    isSelected(id) {
      return !!this.selectedRows.find(row => row === id);
    },
    onFilter () {
      let activeFilters = this.filterChanges
        .filter(i => i.isChecked)
        .reduce((acc, curr) => { acc[curr.field] = acc[curr.field] || []; acc[curr.field].push(curr.value); return acc; }, {});

      let filterItems = (data, filters) => data.filter(value => Object.keys(filters).every(key => filters[key].includes(value[key])));
      let records = filterItems(this.data, activeFilters);

      this.localDataset = records;
      this.localDatasetTotal = records.length;
      this.query.page = 1; // reset the paging
    },
    onPageSizeChange () {
      this.$emit('page-size-changed', { pageSize: this.query.pageSize });
    },
    onRowClass (item) {
      if (typeof(this.rowClass) === 'function') {
        return this.rowClass(item)
      }
      return this.rowClass
    },
    selectRecord(id) {
      let pos = this.selectedRows.indexOf(id);
      if (pos === -1) this.selectedRows.push(id);
      if (pos >= 0) this.selectedRows.splice(pos, 1);
    },
    checkAll() {
      let selectedItems = this.availableRecords;

      let uncheckedItems = selectedItems.map(i => i.id).filter(i => !this.selectedRows.includes(i));
      let isAllChecked = uncheckedItems.length === 0

      if (isAllChecked) {
        for (let record of selectedItems) {
          let pos = this.selectedRows.indexOf(record.id);
          this.selectedRows.splice(pos, 1)
        }
      }
      else {
        uncheckedItems.forEach(i => this.selectedRows.push(i));
      }
    },
    toggleColumnDropdown() {
      this.isOpen = !this.isOpen;
    },
    turnPage(i) {
      this.query.page = +this.query.page + i;
    },
    generateColumnFilters(column) {
      return this.data
        .map(r => r[column.field])
        .filter((value, index, self) => { if (!value) return false; return self.indexOf(value) === index })
        .sort((a, b) => a < b ? -1 : a > b ? 1 : 0);
    }
  },
  created() {
    const q = { }; // add values if need to default/set values on the component level
    Object.keys(q).forEach(key => this.$set(this.query, key, q[key]));

    this.columns.forEach(column => {
      this.$set(column, "isVisible", typeof column.isVisible === "boolean" ? column.isVisible : true);

      if (this.isLocalData && column.filterable) {
        this.$set(column, "filterOptions", this.generateColumnFilters(column));
      }
    });
  },
  mounted () {
    this.localDataset = this.data;
    this.localDatasetTotal = this.total;
  }
};
</script>

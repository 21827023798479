export default {
  methods: {
    getAddressDisplay(address) {
      if (!address) return null;

      let displayString = '';
      let { street, city, province, postalCode } = address;
      const builder = (s, a, comp, sc = true, ss = true) => { s = s + a; if (comp && sc) s = s + ","; if (comp && ss) s = s + " "; return s; }

      if (street) displayString = builder(displayString, street, (city || province || postalCode), true, true);
      if (city) displayString = builder(displayString, city, (province || postalCode), province ? false : true, true);
      if (province) displayString = builder(displayString, displayString === '' ? province.name : province.abbreviation, postalCode, true, true);
      if (postalCode) displayString = builder(displayString, postalCode, false);

      return displayString;
    }
  }
}
